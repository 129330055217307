





































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
    },
})
export default class CompanyDashboardStatistics extends Vue {
    @Prop() statistics!: any[];
}
